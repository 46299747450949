import React from 'react';
import logo from './Logo.png';
import './App.css';
import Footer from './Footer';
import BackgroundImage from './BackgroundImage';
import WhatsappButton from '../components/WhatsappButton';
import Menu from '../components/Menu';
import CompactLoginMenu from './CompactLoginMenu';


function App() {
  return (
    
    <div className="App">
            <header className="App-header">
            <div className="Menu-out"><img src={logo} className="App-logo" alt="logo" style={{ width: '250px', height: 'auto' }} />
                <Menu />
                <CompactLoginMenu />
                <WhatsappButton />
            </div>
            </header >
    <BackgroundImage />
    <Footer />
    </div >
  
    

  );
}

export default App;
